import { InfoTable } from '../../../js/portal/main';

export default function ClusterTabs(props) {
  const { data } = props;

  const cluster_id = data?.project_name?.replaceAll(' ', '_');

  return (
    <div class="detailsContainer">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            data-bs-target={'#project_' + cluster_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="true"
            aria-expanded="true">
            Project
          </a>
        </li>
      </ul>
      <div class="tab-content detailsTab">
        <div class="tab-pane fade active show" id={'project_' + cluster_id} role="tabpanel">
          <div class="row" name="project-container">
            <div class="col-lg-12 col-xl-12">
              <div class="detailsContent">
                <h4>4wheels Project Details</h4>
                <div>
                  <InfoTable
                    data={data}
                    rowInfos={[
                      { Name: 'Account Id', Value: 'account_id' },
                      { Name: 'Account DL', Value: 'account_dl' },
                      { Name: 'Project Name', Value: 'project_name' },
                      { Name: 'Cluster Name', Value: 'details.cluster_name' },
                      { Name: 'Cluster Size', Value: 'cluster_size' },
                      { Name: 'Kubernetes Version', Value: 'details.k8s_version' },
                      { Name: 'VPC ID', Value: 'vpc_id' },
                      { Name: 'Route 53 Zone Name', Value: 'zone_name' },
                      { Name: 'Region', Value: 'region' },
                      { Name: 'Logging Stack', Value: 'details.logging_stack' },
                      { Name: 'Alert Manager', Value: 'details.alertmanager' },
                      { Name: 'Grafana', Value: 'details.grafana' },
                      { Name: 'Kibana', Value: 'details.kibana' },
                      { Name: 'Prometheus', Value: 'details.prometheus' },
                      { Name: 'Resource Group', Value: 'details.resource_group' },
                      { Name: 'Cluster Access Command', Value: 'details.cluster_access_com' },
                      { Name: 'Order Id', Value: 'order_id' },
                      { Name: 'Order Proposer', Value: 'order_proposer' },
                      { Name: 'Creation Date', Value: 'details.creation_date' },
                      { Name: 'Project Status', Value: 'status' },
                      { Name: 'Status Details', Value: 'status_message' },
                      { Name: 'Last Status Update (UTC)', Value: 'status_update' },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
