import swal from 'sweetalert';
import { baseApiAxios } from './js/portal/api';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import { loadHostedZones, setZoneInfo } from './js/portal/zones';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { displaySuccessPopup, hasAccess } from './js/portal/main';
import { getSearchParamsUrl, setSearchParamsUrl } from './js/portal/search_params';

export default function HostedZonesDestroyPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="destroy-route53-hosted-zone-form" id="destroy-route53-hosted-zone-form" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Destroy Route 53 Hosted Zone</div>

            {/* CARD BODY */}
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  With this form you can request the Route 53 Hosted Zone deletion from your AWS Account. It will
                  automatically delete the association with all VPCs (for Private Hosted Zones) or the subdomain
                  delegation (for Public Hosted Zones). As soon as the Hosted Zone is deleted, the domain will be
                  available again for others.
                </div>
              </div>

              <AccountDropdown
                id="aws-account-id"
                onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                  document.getElementById('submitButton').disabled = !hasAccess(accountId, ['manage_dns']);
                  loadHostedZones('fqdn', 'aws-account-id');
                }}
                short
                lookup/>

              <div class="form-group row">
                <label for="fqdn" class="col-form-label">
                  Hosted Zone
                  <span class="loading-form-inline">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </span>
                </label>
                <div class="col">
                  <select
                    required
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    id="fqdn"
                    name="fdqn"
                    data-size="10"
                    data-none-selected-text="- Select a Hosted Zone -" />
                </div>
              </div>

              <div class="form-group row">
                <label for="hosted-zone-id-text" class="col-form-label">
                  ID
                </label>
                <div class="col">
                  <input required class="form-control" id="hosted-zone-id-text" name="hosted-zone-id" disabled />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label" />
                <div class="col">
                  <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      value="force-delete"
                      id="force-delete"
                      name="force-delete"
                      onchange={() => warnForceDelete()}
                      checked/>
                    <strong>Force deletion of all records inside the Hosted Zone</strong>
                  </label>
                </div>
              </div>
            </div>

            {/* CARD FOOTER */}
            <div class="card-footer">
              <input type="hidden" name="action" id="action" value="createJob" />
              <input type="hidden" name="jobType" id="jobType" value="destroy-hostedzone" />
              <input
                type="hidden"
                name="jobTypeDescription"
                id="jobTypeDescription"
                value="Destroy Route53 Private Hosted Zone"/>
              <div class="row px-2">
                <div class="col">
                  <input
                    type="button"
                    name="resetFormBtn"
                    class="btn btn-secondary"
                    onclick={() => location.reload()}
                    value="Reset"/>
                </div>
                <div class="col" align="right">
                  <button id="submitButton" type="submit" class="btn btn-success" value="Order Hosted Zone Deletion">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Order Hosted Zone Deletion
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function warnForceDelete() {
    if ($('#force-delete')[0].checked) {
      swal({
        title: 'Are you sure?',
        text: 'This option will permanently delete all the records that belong to this hosted zone',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancel',
            value: null,
            visible: true,
          },
          okay: {
            text: 'Accept',
            value: true,
            visible: true,
          },
        },
      }).then(function (response) {
        if (!response) {
          $('#force-delete')[0].checked = false;
        }
      });
    }
  }

  function warnSubmit() {
    const sel = document.getElementById('fqdn');
    const fqdn = sel.options[sel.selectedIndex].text;
    swal({
      title: 'Are you sure that you want to delete this hosted zone?',
      text: 'This order will permanently delete the hosted zone: ' + fqdn,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
        },
        okay: {
          text: 'Accept',
          value: true,
          visible: true,
        },
      },
    }).then(function (response) {
      if (response) {
        addSpinner();
        addButtonSpinner();
        submitDeleteHZOrder();
      }
    });
  }

  function resultFunction(result) {
    removeSpinners();
    const form = document.getElementById('destroy-route53-hosted-zone-form');
    displaySuccessPopup(result.message, 'Create Order');
    form.reset();
    $('.selectpicker').selectpicker('refresh');
    $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
  }

  function submitDeleteHZOrder() {
    const sel = document.getElementById('fqdn');
    const zone_id = sel.value.split(',')[0].split('/')[2];
    const fqdn = sel.value.split(',')[1];

    const payload = {
      action: $('#jobType')[0].value,
      description: 'Destroy hosted zone: ' + fqdn,
      account_id: $('#aws-account-id')[0].value.split(';')[0],
      zone_name: fqdn,
      hosted_zone_id: zone_id,
      force_deletion: $('#force-delete')[0].checked,
    };

    baseApiAxios
      .createOrder(payload)
      .then(resultFunction)
      .catch(err => {
        $('#form-error').html('<strong>Warning: </strong>' + err.message + ' ');
        $('#form-error').show();
      });
  }

  function onPageReady() {
    $('.selectpicker').selectpicker('refresh');
    $('#destroy-route53-hosted-zone-form').on('submit', function (e) {
      e.preventDefault();
      warnSubmit();
    });

    const fqdnSelect = $('#fqdn');

    fqdnSelect.on('changed.bs.select', evt => {
      const selectedOption = $(evt.target).find(':selected');
      const selectedName = selectedOption.data('name');
      setZoneInfo();
      setSearchParamsUrl({ zone: selectedName });
    });
    
    fqdnSelect.on('dropdown-options-loaded', function (e) {
      const zoneName = getSearchParamsUrl('zone');
      if (zoneName && e.detail && e.detail.zones) {
        e.target.querySelectorAll('option').forEach(option => {
          if (option.dataset.name === zoneName) option.selected = true;
        });
        $(e.target).selectpicker('refresh');
        setZoneInfo();
      }
    });

  }

  return [page, onPageReady];
}
